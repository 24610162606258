import {useLazyQuery, useMutation} from '@apollo/client';
import IntegrityStatusCard from '@app/components/Common/Block/IntegrityStatus/IntegrityStatusBlock/IntegrityStatusCard';
import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader';
import NoResult from '@app/components/Common/Text/NoResult';
import SpinnerLoaderComponent from '@app/components/Loaders/SpinnerLoaderComponent';
import {CalculateEventIntegrityStatusByIdMutation, GetIntegrityStatusesByFlocIdsQuery, IntegrityStatus} from '@app/graphql/__types__/graphql';
import {INTEGRITY_STATUSES_GET_BY_FLOC_IDS, WORKORDER_EVENTS_CALCULATE_INTEGRITY_STATUS_BY_ID} from '@app/graphql/requests';
import AppNotifications from '@app/services/notification';
import {useEventStore} from '@app/stores/event';
import {useLayoutStore} from '@app/stores/layout';
import useOptimusConfig from '@app/utils/hooks/useOptimusConfig';
import useUserPermissions from '@app/utils/hooks/useUserPermissions';
import {RadButton} from '@holis/react-ui/rad';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {LuCalculator, LuClipboardList} from 'react-icons/lu';

type TIntegrityStatuses = Readonly<{
  readonly?: boolean;
}>;

export default function IntegrityStatuses({readonly}: TIntegrityStatuses) {
  const _config = useOptimusConfig();
  const prms = useUserPermissions();
  const {t} = useTranslation();
  const {startLoading, stopLoading} = useLayoutStore();
  const {eventFlocIds, activeEvent, eventIntegrityStatuses, setEventIntegrityStatuses} = useEventStore();
  const [getEventIntegrityStatusesByFlocIdsApi, {error, loading}] = useLazyQuery<GetIntegrityStatusesByFlocIdsQuery>(INTEGRITY_STATUSES_GET_BY_FLOC_IDS);
  const [calculateEventIntsApi] = useMutation<CalculateEventIntegrityStatusByIdMutation>(WORKORDER_EVENTS_CALCULATE_INTEGRITY_STATUS_BY_ID);

  const calculateInts = () => {
    startLoading();
    calculateEventIntsApi({
      variables: {
        id: activeEvent!.id!,
      },
    }).then(() => {
      fetchEventIntegrityStatuses();
      AppNotifications.success(t('message.success.calculateInts'));
    }).catch(() => {
      AppNotifications.error(t('message.error.default.title'));
    }).finally(stopLoading);
  };

  const integrityStatusUpdated = (integrityStatus: Partial<IntegrityStatus>) => {
    if (!eventIntegrityStatuses) {
      return;
    }

    setEventIntegrityStatuses(eventIntegrityStatuses?.map(ints => ints.id === integrityStatus.id ? integrityStatus : ints));
  };

  const fetchEventIntegrityStatuses = () => {
    if (Array.isArray(eventFlocIds) && eventFlocIds.length) {
      getEventIntegrityStatusesByFlocIdsApi({
        variables: {
          flocIds: eventFlocIds,
        },
        fetchPolicy: 'no-cache',
      }).then(queryResult => {
        setEventIntegrityStatuses((queryResult.data?.integrityStatuses ?? []) as Partial<IntegrityStatus>[]);
      });
    } else {
      setEventIntegrityStatuses([]);
    }
  };

  useEffect(() => {
    fetchEventIntegrityStatuses();
  }, [eventFlocIds]);
  return (
    <div className='flex flex-col gap-2 w-full'>
      <FormGroupHeader
      >
        <div className='flex gap-4 items-center'>
          <div className='flex items-center'>
            <LuClipboardList className='mr-2' size={20}/> {t('label.integrityStatus')} {/* }({integrityStatuses?.length ?? 0}){ */}
          </div>
          <RadButton variant='outline' disabled={readonly || !prms.flocs.manageIntegrity} className='text-foreground' onClick={calculateInts}><LuCalculator className='mr-2'/> {t('label.calculate')}</RadButton>
        </div>
      </FormGroupHeader>
      <SpinnerLoaderComponent isLoading={loading} error={!loading && !!error} contentClassName='flex flex-col gap-2'>
        {
          eventIntegrityStatuses?.map(ints => (
            <IntegrityStatusCard key={`ints-${ints.id}`} hasMenuContext integrityStatus={ints} readonly={readonly || !prms.flocs.manageIntegrity} onIntegrityStatusUpdated={integrityStatusUpdated}/>
          ))
        }
        {Array.isArray(eventIntegrityStatuses) && !eventIntegrityStatuses.length && <NoResult/>}
      </SpinnerLoaderComponent>
    </div>
  );
}
