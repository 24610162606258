import SinglePageVerticalTabs from '@app/components/Common/Tabs/SinglePageVerticalTabs';
import FlocInfo from '@app/components/Modal/MultiplePanels/MethodEngineering/Flocs/FlocInfo';
import {FunctionalLocation} from '@app/graphql/__types__/graphql';
import {TAppTabItem} from '@app/types/app';
import IanList from './IanList';
import {useTranslation} from 'react-i18next';
import IntegrityStatusBlock from './IntegrityStatus';
import InspectionPointList from '@app/components/Modal/MultiplePanels/MethodEngineering/Flocs/InspectionPointList';
import useUserPermissions from '@app/utils/hooks/useUserPermissions';
import CmlList from '@app/components/Modal/MultiplePanels/MethodEngineering/Flocs/CmlList';
import DamageList from '@app/components/Modal/MultiplePanels/MethodEngineering/Flocs/DamageList';
import {useFlocStore} from '@app/stores/methodEngineering/floc';

export type TLeftSideProps = Readonly<{
	floc: Partial<FunctionalLocation>;
}>;

export default function LeftSide({floc}: TLeftSideProps) {
  const {t} = useTranslation();
  const prms = useUserPermissions();
  const {inspectionPoints, cmls, damages, editFloc, notifications, integrityStatus} = useFlocStore();
  const items: TAppTabItem[] = [
    {
      content: <FlocInfo floc={floc}/>,
      title: t('label.header'),
      value: 'header',
    },
    {
      content: <IanList/>,
      title: t('label.IAN'),
      value: 'ian',
      counterValue: notifications?.length ?? editFloc?._count?.notifications ?? 0,
    },
    ...(!integrityStatus ? [] : [
      {
        content: <IntegrityStatusBlock/>,
        title: t('label.integrityStatus'),
        value: 'integrity',
      },
    ]),
    {
      content: <InspectionPointList floc={floc} readonly={!prms.flocs.create && !prms.flocs.update}/>,
      title: t('label.checklist'),
      value: 'checklist',
      counterValue: inspectionPoints?.length ?? editFloc?._count?.inspectionPoints ?? 0,
    },
    {
      content: <CmlList floc={floc}/>,
      title: t('label.cml'),
      value: 'cml',
      counterValue: cmls?.length ?? editFloc?._count?.cmls ?? 0,
    },
    {
      content: <DamageList floc={floc}/>,
      title: t('label.damage'),
      value: 'damage',
      counterValue: damages?.length ?? editFloc?._count?.damages ?? 0,
    },
  ];
  return (
    <div className='w-full relative h-full'>
      <SinglePageVerticalTabs
        items={items}
      />
    </div>
  );
}
